<template>
  <div>
    <div class="container">
      <div class="head flex">
        <h1 @click="zixun()">{{ aboutname }}</h1>
        <p
          v-if="
            aboutname != columnName &&
              columnName != undefined &&
              aboutname != ''
          "
        >
          |
        </p>
        <h1 v-if="aboutname != columnName" class="color2c">{{ columnName }}</h1>
        <p>
          <span @click="son2()">首页</span>
          <span
            v-if="
              aboutname != columnName &&
                columnName != undefined &&
                aboutname != ''
            "
            @click="zixun()"
            >{{ aboutname }}</span
          >
          <span>{{ columnName }}</span>
        </p>
      </div>
      <div class="bottom flex">
        <div class="left">
          <div class="list">
            <ul>
              <li
                class=""
                v-for="(item, idx) in list"
                :key="idx"
                @click="son(item)"
              >
                <div class="">
                  <p class="infor">{{ item.title | ellipsis }}</p>
                  <div class=" source">
                    <p
                      v-if="item.sourceValue != '' || item.websetName != ''"
                      class="source-left"
                    >
                      来源:{{
                        item.sourceValue == ""
                          ? item.websetName
                          : item.sourceValue
                      }}
                    </p>
                    <p
                      v-if="item.sourceValue == '' && item.websetName == ''"
                      class="source-left"
                    >
                      来源:本网站
                    </p>
                    <p class="source-right">
                      发布时间:<span>{{ item.releaseTime.slice(0, 16) }}</span>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="right">
          <div class="top">
            <h1>即时热点<span>HOT</span></h1>
            <ul>
              <li v-for="(item, idx) in hotList" :key="idx" @click="jump(item)">
                <i
                  ><span>{{ idx + 1 }}</span></i
                >
                {{ item.title }}
              </li>
            </ul>
          </div>
          <!-- <div class="ad" v-for="(item, idx) in bannerImg" :key="idx">
            <img :src="item.picturePath" alt="" />
          </div> -->
          <div v-if="bannerImg.length > 0">
            <template v-if="bannerImg.length > 1">
              <el-carousel indicator-position="none" arrow="never">
                <el-carousel-item
                  v-for="(item, index) in bannerImg"
                  :key="index"
                >
                  <img :src="item.picturePath" style="width:100%;" />
                </el-carousel-item>
              </el-carousel>
            </template>
            <template v-else>
              <div class="ad" v-for="(item, idx) in bannerImg" :key="idx">
                <img :src="item.picturePath" alt="" />
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="Pagination">
        <ul class="flex">
          <li v-if="cur > 1"><a @click="cur--, pageClick()">上一页</a></li>
          <li v-if="cur == 1"><a class="banclick">上一页</a></li>
          <li v-for="idx in indexs" :key="idx" :class="{ active: cur == idx }">
            <a @click="btnClick(idx)">{{ idx }}</a>
          </li>
          <li v-if="cur != all"><a @click="cur++, pageClick()">下一页</a></li>
          <li v-if="cur == all"><a class="banclick">下一页</a></li>
          <li>
            <a
              >共<i>{{ all }}</i
              >页</a
            >
          </li>
        </ul>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { getBanner } from "@/api/banner";
import { getQuotes, getQuotesList, getNews } from "@/api/agricuinfotThree";
import { getdetail } from "@/api/home1";
import Footer from "../../components/footer.vue";
export default {
  components: { Footer },
  data() {
    return {
      Img: [],
      label: [],
      currentIndex: 0, //默认显示图片
      timer: null, //定时器
      all: 10, //总页数
      cur: 1, //当前页码
      totalPage: 0, //当前条数
      list: "",
      hotList: "",
      aboutname: "",
      columid: "",
      paths: "",
      columnName: "",
      columnNameId: "",
      baner: "",
      bannerImg: []
    };
  },
  methods: {
    getbanner() {
      getBanner({
        // columnId: this.columnNameId,
        columnId: this.$route.query.columnNameId,
        type: 2
      }).then(res => {
        this.bannerImg = res.data;
      });
    },
    gotoPage(index) {
      this.currentIndex = index;
    },
    runInv() {
      this.timer = setInterval(() => {
        this.gotoPage(this.nextIndex);
      }, 3000);
    },
    mouseOver() {
      clearInterval(this.timer);
    },
    mouseLeave() {
      this.runInv();
    },
    dataList(idx) {
      getdetail({
        id: this.$route.query.columnNameId
      }).then(res => {
        this.columnName = res.data.columnName;
        this.aboutname = res.data.parentColumnName;
        this.paths = res.data.parentAccessPath;
        this.columid = res.data.parentId;
      });
      getQuotesList({
        columnId: this.columnNameId,
        size: 15,
        current: idx
      }).then(res => {
        this.list = res.data.records;
        this.all = res.data.pages;
        this.cur = res.data.current;
        this.titalPage = res.data.total;
      });
    },
    btnClick(data) {
      if (data != this.cur) {
        //判断是不是当前页，不是就计算
        this.cur = data;
      }
      this.dataList(this.cur.toString());
    },
    pageClick() {
      this.dataList(this.cur.toString());
    },
    getcolum() {
      getQuotes({
        parentId: this.columnNameId
      }).then(res => {
        this.dataList(this.cur.toString());
      });
    },
    gethot() {
      getNews({
        type: 2,
        columnId: this.columid
      }).then(res => {
        this.hotList = res.data.records;
      });
    },
    zixun() {
      if (this.columid != "1393021449385963522") {
        this.$router.push({
          path: this.paths,
          query: {
            columid: this.columid
          }
        });
      }
    },
    getList() {
      getQuotesList({
        columnId: this.columnNameId
      }).then(res => {
        this.baner = res.data.records;
        for (let i = 0; i < 3; i++) {
          this.Img.push(res.data.records[i].icon);
          this.label.push(res.data.records[i].title);
        }
      });
    },
    son(item) {
      let routerJump = this.$router.resolve({
        path: "/domesticDetails",
        query: {
          // columnNameId: this.columnNameId,
          titleId: item.id
          // columid:this.columid
        }
      });
      window.open(routerJump.href, "_blank");
    },
    son1() {
      let routerJump = this.$router.resolve({
        path: "/domesticDetails",
        query: {
          // columnNameId: this.columnNameId,
          titleId: this.baner[this.currentIndex].id
          // columid:this.columid
        }
      });
      window.open(routerJump.href, "_blank");
    },
    jump(item) {
      let routerJump = this.$router.resolve({
        path: "/domesticDetails",
        query: {
          // columnNameId: this.columnNameId,
          titleId: item.id
          // columid:this.columid
        }
      });
      window.open(routerJump.href, "_blank");
    },
    son2() {
      this.$router.push("/home");
    }
  },
  mounted() {
    // this.aboutname=this.$route.query.aboutname
    // this.columid=this.$route.query.columid
    // this.paths=this.$route.query.paths
    // this.columnName=this.$route.query.columnName
    this.columnNameId = this.$route.query.columnNameId;
    this.getbanner();
    this.runInv();
    this.getcolum();
    this.gethot();
    this.getList();
  },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 45) {
        return value.slice(0, 45) + "...";
      }
      return value;
    }
  },
  computed: {
    //上一张
    prevIndex() {
      if (this.currentIndex == 0) {
        return this.Img.length - 1;
      } else {
        return this.currentIndex - 1;
      }
    },
    //下一张
    nextIndex() {
      if (this.currentIndex == this.Img.length - 1) {
        return 0;
      } else {
        return this.currentIndex + 1;
      }
    },
    indexs() {
      var left = 1; //默认起始值是1
      var right = this.all; //终止值是全部
      var arr = [];
      if (this.all >= 5) {
        if (this.cur > 3 && this.cur < this.all - 2) {
          left = this.cur - 2;
          right = this.cur + 2;
        } else {
          if (this.cur <= 3) {
            left = 1;
            right = 5;
          } else {
            right = this.all;
            left = this.all - 4;
          }
        }
      }
      while (left <= right) {
        arr.push(left);
        left++;
      }
      return arr;
    }
  }
};
</script>

<style scoped>
.bottom {
  height: 100%;
}
.left {
  width: 60%;
  height: 100%;
}
.right {
  max-width: 35%;
  height: 100%;
  margin-left: 5%;
}
.ul {
  height: 30px;
  padding-right: 20px;
  justify-content: space-between;
}
.ul .li {
  list-style: none;
  margin: 0 10px;
  width: 10px;
  height: 10px;
  /* margin-top: 10px; */
  text-align: center;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  border-radius: 50%;
  border: 1px solid #fff;
  float: left;
}
.banner {
  width: 100%;
  position: relative;
  cursor: pointer;
}
.banner img {
  width: 100%;
  height: 478px;
  display: block;
}
.banner .page {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: 0;
  bottom: 0;
  width: 780px;
  align-items: center;
}
.banner .page .ul {
  float: right;
  padding-top: 10px;
}
.current {
  background-color: #fff;
  border: none;
  width: 12px;
  height: 12px;
}
.font {
  text-align: left;
  color: #fff;
  float: left;
  line-height: 40px;
  padding-left: 10px;
}
.list li {
  width: 100%;
  position: relative;
  border-bottom: 1px solid #dcdcdc;
  font-size: 14px;
  cursor: pointer;
  padding: 15px 0;
}
.list .infor {
  color: #333;
  font-size: 16px;
}
.list .source {
  width: 100%;
  color: #999;
  overflow: hidden;
  padding: 5px 0;
}
.list .source .source-left {
  float: left;
}
.list .source .source-left {
  float: right;
}

.right .top h1 {
  margin: 10px 0 30px;
  color: #51d5c3;
  font-weight: bold;
}
.right .top h1 span {
  color: #c00000;
  margin-left: 10px;
}
.right .top li {
  margin: 12px 0;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.right .top li:hover {
  color: #51d5c3;
}
.right .top li span {
  background-color: #51d5c3;
  display: inline-block;
  padding: 0 5px;
  position: relative;
  top: -3px;
  left: -3px;
  color: #fff;
}
.right .top li i {
  border: 1px solid #51d5c3;
  margin-right: 10px;
}
.ad img {
  margin-top: 20px;
  width: 100%;
  height: 240px;
  cursor: pointer;
}
.Pagination {
  margin: 40px auto;
  margin-top: 50px;
}
.Pagination ul {
  justify-content: center;
}
.Pagination li:first-child > a {
  margin-left: 0px;
}
.Pagination a {
  border: 1px solid #000;
  text-decoration: none;
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  color: #5d6062;
  cursor: pointer;
  margin-right: 20px;
}
.Pagination a:hover {
  background-color: #eee;
}
.Pagination a.banclick {
  cursor: not-allowed;
}
.Pagination .active a {
  color: #fff;
  cursor: default;
  background-color: #51d5c3;
  border-color: #51d5c3;
}
.Pagination i {
  font-style: normal;
  color: #51d5c3;
  margin: 0px 4px;
  font-style: 12px;
}
.head {
  border-top: 3px solid #51d5c3;
  width: 100%;
  padding: 20px 0;
  align-items: center;
  justify-content: left;
}
.head h1 {
  margin: 0 10px;
  font-weight: bold;
  color: #51d5c3;
}
.color2c {
  color: #2c2c2c !important;
}
.head span {
  color: #797979;
}
.head span + span::before {
  content: ">";
  margin: 0 5px;
  color: #797979;
}
.list .infor:hover {
  color: #51d5c3;
}
.container p span:nth-child(1),
.container p span:nth-child(2) {
  cursor: pointer;
}
.container p span:hover {
  color: #51d5c3;
}
.container p span:last-child:hover {
  color: #797979;
}
.ss {
  justify-content: space-between;
}
</style>
