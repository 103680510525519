<template>
  <div>
    <div class="footer">
      <div class="footer-top">
        <h1><span>友情链接</span></h1>
        <ul class="flex">
          <li><a href="https://www.wens.com.cn" target="_blank">温氏股份</a></li>
          <li><a href="https://www.junciot.com" target="_blank">筠诚物联网</a></li>
          <li><a href="http://www.xinm123.com" target="_blank">新牧网</a></li>
          <li><a href="http://www.chinafeeddata.org.cn/admin/Login/index.html" target="_blank">中国饲料数据库</a></li>
          <li><a href="http://www.dce.com.cn/dalianshangpin/sspz/sz/index.html" target="_blank">生猪期货</a></li>
          <li><a href="http://www.gdswine.com" target="_blank">养猪信息网</a></li>
          <!-- <li><a href="http://www.xmdj123.com">畜牧大集网</a></li> -->
          <li><a href="https://www.zhue.com.cn" target="_blank">猪易网</a></li>
          <li><a href="http://www.gdfeed.org.cn" target="_blank">广东饲料信息网</a></li>
          <li class="bordernone">
            <a href="https://www.wepig.cn" target="_blank">微猪科技</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="copyright">
      <h2>
        <span class="mr50"
          >主管单位：<a :href="constCommon.zhubanUrl" target="_blank">{{
            constCommon.zhuban
          }}</a></span
        ><span 
          >网站运营：<a href="https://www.junciot.com" target="_blank">{{
            websiteSign
          }}</a></span
        ><!-- <span>平台支持：中国电信股份有限公司梅州分公司</span> -->
      </h2>
      <h3>
        <img src="@/assets/img/foot-img_03.png" /><a
          href="https://beian.miit.gov.cn/"
          target="_blank"
          >粤ICP备{{ contentProvider }}</a
        >
      </h3>
    </div>
  </div>
</template>

<script>
import { getWebTitle } from "../../api/home1";
import { constCommon } from "@/consts/common";
export default {
  data() {
    return {
      constCommon: constCommon,
      websiteSign: "",
      contentProvider: ""
    };
  },
  methods: {
    getWebTitle2() {
      getWebTitle().then(res => {
        if (!res.data.records[0].status) {
          this.contentProvider = res.data.records[0].contentProvider;
          this.websiteSign = res.data.records[0].websiteSign;
        }
      });
    }
  },
  mounted() {
    //获取网站名称
    this.getWebTitle2();
  }
};
</script>

<style scoped>
.footer {
  margin: 38.5px auto 0;
  background-color: #fff;
  position: relative;
  width: 90%;
  max-width: 1300px;
}
.footer .footer-top {
  width: 100%;
  margin: 0 auto 35px;
}
.footer .footer-top h1 {
  text-align: left;
  color: #000;
  font-size: 18px;
}
.footer .footer-top ul {
  overflow: hidden;
  margin: 16px auto 0;
  display: flex;
  justify-content: space-between;
}
.footer li {
  font-size: 16px;
  color: #000;
  text-align: center;
  border-right: 2px solid #878888;
  height: 22px;
  padding: 0 2% 0 0;
}
.footer .bordernone {
  border-right: none;
}
/* .footer li+li::before{
    content: "|";
    margin: 35px;
    color: #878888;
  } */
.copyright {
  width: 100%;
  background-color: #51d5c3;
  bottom: 0;
  color: #fff;
  text-align: center;
  padding: 10px 0 10px;
}
.footer a {
  color: #878888;
  font-size: 17px;
}
.footer a:hover {
  color: #51d5c3;
}
.footer .footer-top h1 span {
  padding-bottom: 5px;
  border-bottom: 1px solid #fff;
  display: inline-block;
  border-top: 6px solid #51d5c3;
  font-weight: bolder;
  padding-top: 8px;
  padding-right: 10px;
}
.copyright h2,
.copyright h3 {
  font-size: 14px !important;
}
.copyright h2 {
  line-height: 40px;
}
.copyright img {
  width: 17px;
  height: 20px;
}
.copyright h3 {
  position: relative;
}
.copyright h3 img {
  margin-right: 16px;
  vertical-align: middle;
}
.mr50 {
  margin-right: 50px;
}
.copyright a {
  color: #fff !important;
  cursor: pointer !important;
}
</style>
